import axios from "axios";
import Layout from "./components/Layout";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { FaPlus, FaTrash, FaEdit } from "react-icons/fa";
import { useHistory , useParams } from "react-router";
export default function BlogsDashboard() {
    const [blogs, setBlogs] = useState([]);
    const [edit, setEdit] = useState(false);
    const [blog, setBlog] = useState(false);
    const [form, setForm] = useState(false);
    const [disableBtn, setDisbale] = useState(false);
    const history = useHistory();


    const getBlogs = () => {
        axios
            .get(`/api/blogs`)
            .then((response) => {
                setBlogs(response.data.message);
            })
            .catch((err) => toast.error(err));
    };

    useEffect(getBlogs, []);

    const handleSubmit = (e) => {
        let formData = new FormData();
        formData.append("cover", form.cover);
        e.preventDefault();
        setDisbale(true);
        axios
            .patch(`/api/blogs/cover/${blog.id}`, formData)
            .then((response) => {
                setDisbale(false);
                getBlogs();
                setEdit(false);

            })
            .catch((err) => {
                console.log(err);
                setDisbale(false);
                toast.error("Error 😔");
            });

    }

    const handleImage = (e) => setForm({ ...form, ["cover"]: e.target.files[0] });

    const deleteBlog = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover the blog!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal("Poof! blog has been deleted!", {
                    icon: "success",
                });
                axios
                    .delete(`/api/blogs/${id}`)
                    .then((response) => {
                        getBlogs();
                    })
                    .then((response) => {
                        console.log(response);
                    })
                    .catch((err) => toast.error("Error 😔"));
            } else {
                swal("Blog is safe!");
            }
        });
    };

    return (
        <Layout>
            {
                !edit && (
                    <div className="table">
                        <h2 className="title">Blogs</h2>
                        <Link to="/dashboard/blogs/add" className="btn btn-success">
                            <FaPlus style={{ marginRight: 5 }} />
                            Add New
                        </Link>
                        <table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Cover</th>
                                    <th>Title</th>
                                    <th>Username</th>
                                </tr>
                            </thead>
                            <tbody>
                                {blogs.map((el) => (
                                    <tr key={el.id}>
                                        <td>{el.id}</td>
                                        <td><img src={`${axios.defaults.baseURL}/public/${el.cover}`} alt="" style={{ width: "100px" }} srcset="" /></td>
                                        <td>{el.title}</td>
                                        <td>{el.username}</td>
                                        <td>
                                            <button
                                                className="btn btn-danger"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    deleteBlog(el.id);
                                                }}
                                            >
                                                <FaTrash style={{ marginRight: 5 }} />
                                                Delete
                                            </button>
                                            <button
                                                className="btn btn-edit"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setBlog(el);
                                                    setEdit(true);
                                                }}
                                            >
                                                <FaEdit style={{ marginRight: 5 }} />
                                                Edit Cover
                                            </button>
                                            <button
                                                className="btn btn-info"
                                                onClick={(e) => {
                                                    console.log(e);
                                                    history.push(`/dashboard/blogs/edit/${el.id}`);
                                                }}
                                            >
                                                <FaEdit style={{ marginRight: 5 }} />
                                                Edit
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )
            }
            {edit && (
                // <div style={{minHeight: "100vh" , display:"flex" , justifyContent:"center" , alignItems:"center" , width:"100%"}}>
                <form className="form" onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label>Cover</label>
                        <input type="file" onChange={handleImage} />
                    </div>
                    <button className="btn btn-normal" style={{ marginBottom: "5px" }} disabled={disableBtn}>
                        {/* <FaEdit style={{ marginRight: 5 }} /> */}
                        Submit
                    </button>
                    <button className="btn btn-normal" onClick={() => setEdit(false)}>
                        {/* <FaEdit style={{ marginRight: 5 }} /> */}
                        Back
                    </button>
                </form>
                // </div>
            )
            }
        </Layout>
    );
}
