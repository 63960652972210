import React, { useState } from 'react'
import EventLayout from '../../components/EventLayout'
import { CSSTransition } from 'react-transition-group';
import Form from '../../components/helwanActivity/Form';
import HelwanButtons from '../../components/helwanActivity/HelwanButtons';
import CustomWheel from '../../components/helwanActivity/CustomWheel';
import Questions from '../../components/helwanActivity/Questions';
import Result from '../../components/helwanActivity/Result';

export default function HelwanActivity() {
    const questions = {
        1: [
            {
                "title": "كم رمضان صامه النبي ص في حياته؟",
                "rightChoiceIndex": 1,
                "choices": ["٨ رمضانات", "٩ رمضانات", "١٠ رمضانات"]
            },
            {
                "title": "من أول صحابي جمع المسلمين لقيام صلاة التراويح؟",
                "rightChoiceIndex": 1,
                "choices": ["أبو بكر الصديق", "عمر ابن الخطاب", "علي ابن ابي طالب"]
            },
            {
                "title": "متى فُرض الصيام على النبي والمسلمين؟",
                "rightChoiceIndex": 1,
                "choices": ["في السنة الاولي من الهجرة", "في السنة الثانية من الهجرة", "في السنة الثالثة من الهجرة"]
            },
            {
                "title": "ما ترتيب شهر رمضان في السنة الهجرية؟",
                "rightChoiceIndex": 1,
                "choices": ["الشهر الثامن", "الشهر التاسع", "الشهر العاشر"]
            },
            {
                "title": "ما هي الفاكهة التي يعد الإفطار عليها من السنة؟",
                "rightChoiceIndex": 2,
                "choices": ["التين", "الزيتون" , "التمر"]
            },
            {
                "title": "ما هو الشهر الذي يسبق شهر رمضان؟",
                "rightChoiceIndex": 0,
                "choices": ["شهر شعبان" , "شهر رجب", "شهر ذي الحجة"]
            },
            {
                "title": "كم عدد الأحزاب في القرآن الكريم؟",
                "rightChoiceIndex": 1,
                "choices": ["٥٠ حزب", "٦٠ حزب", "٧٠ حزب"]
            },
            {
                "title": "من هو الصحابي الجليل الذي تستحي الملائكة في السماء منه؟",
                "rightChoiceIndex": 2,
                "choices": ["أبو بكر الصديق", "عمر ابن الخطاب" , "عثمان بن عفان"]
            },
            {
                "title": "من هو الصحابي الجليل الذي كان تسلم عليه الملائكة؟",
                "rightChoiceIndex": 2,
                "choices": ["عثمان بن عفان" , "عمر ابن الخطاب" , "عمران ابن حصة"]
            },
            {
                "title": "من هو أول من قام ببناء السجون في الدولة الإسلامية؟",
                "rightChoiceIndex": 0,
                "choices": ["علي بن أبي طالب" , "عمر ابن الخطاب" , "عثمان ابن عفان"]
            },
            {
                "title": "من هو أول من اعتنق التوحيد في مكة؟",
                "rightChoiceIndex": 1,
                "choices": ["عمر ابن الخطاب", "ورقة بن نوفل", "عثمان ابن عفان"]
            },
            {
                "title": "من هو أول من رفع الأذان في السماوات؟",
                "rightChoiceIndex": 2,
                "choices": ["إبراهيم عليه السلام", "إسماعيل عليه السلام" , "جبريل عليه السلام"]
            },
            {
                "title": "كم مرة ورد ذكر شهر رمضان في القرآن الكريم؟",
                "rightChoiceIndex": 0,
                "choices": ["مرة واحدة", "مرتين", "اربع مرات"]
            },
            {
                "title": "في أي يوم من رمضان وقعت غزوة بدر؟",
                "rightChoiceIndex": 1,
                "choices": ["١٦ رمضان", "١٧ رمضان", "١٨ رمضان"]
            },
            {
                "title": "ما اسم الباب الذي يدخل منه الصائمون يوم القيامة وهو أحد أبواب الجنة الثمانية؟",
                "rightChoiceIndex": 1,
                "choices": ["باب المتوكلين", "باب الريان", "باب الذكر"]
            },
            {
                "title": "كم مرة ورد ذكر اسم ”ليلة القدر“ في القرآن الكريم بنفس اللفظ، أي لفظ ”ليلة القدر“؟",
                "rightChoiceIndex": 1,
                "choices": ["مرتين", "٣ مرات", "٤ مرات"]
            },
            {
                "title": "ما هو الشهر الذي يأتي بعد رمضان؟",
                "rightChoiceIndex": 2,
                "choices": ["شهر رجب", "شهر شعبان", "شهر شوال"]
            },
            {
                "title": "ما الإسم الذي أطلقه العرب قديماً على شهر رمضان المبارك؟",
                "rightChoiceIndex": 1,
                "choices": ["زاهر", "ناتق", "رمضان"]
            },
            {
                "title": "كم عدد الأرباع في القرآن الكريم؟",
                "rightChoiceIndex": 1,
                "choices": ["٢٣٠ ربع", "٢٤٠ ربع", "٢٥٠ ربع"]
            }
        ],
        2: [
        ],
        3: [
        ]
    }
    const [step, setStep] = useState(1);
    const [choice, setChoice] = useState(1);
    const [question, setQuestion] = useState(questions[1][0]);
    const [status, setStatus] = useState("P");
    const headerprops = {
        maintext: "Activity",
        slogan: "",
    };
    return (
        <EventLayout headerconfig={headerprops}>

            <CSSTransition
                in={step === 1}
                timeout={{ enter: 100, exit: 100 }}
                classNames={{
                    enter: 'slide-enter',
                    enterActive: 'slide-enter-active',
                    exit: 'slide-exit',
                    exitActive: 'slide-exit-active'
                }}
                unmountOnExit
            >
                <Form setStep={setStep} />
            </CSSTransition>

            {/* <CSSTransition
                in={step === 2}
                timeout={{ enter: 100, exit: 100 }}
                classNames={{
                    enter: 'slide-enter',
                    enterActive: 'slide-enter-active',
                    exit: 'slide-exit',
                    exitActive: 'slide-exit-active'
                }}
                unmountOnExit
            >
                <HelwanButtons setChoice={setChoice} setStep={setStep} />
            </CSSTransition> */}

            <CSSTransition
                in={step === 2}
                timeout={{ enter: 100, exit: 100 }}
                classNames={{
                    enter: 'slide-enter',
                    enterActive: 'slide-enter-active',
                    exit: 'slide-exit',
                    exitActive: 'slide-exit-active'
                }}
                unmountOnExit
            >
                <CustomWheel setStep={setStep} questions={questions[choice]} setQuestion={setQuestion} />
            </CSSTransition>

            <CSSTransition
                in={step === 3}
                timeout={{ enter: 100, exit: 100 }}
                classNames={{
                    enter: 'slide-enter',
                    enterActive: 'slide-enter-active',
                    exit: 'slide-exit',
                    exitActive: 'slide-exit-active'
                }}
                unmountOnExit
            >
                <Questions question={question} setStep={setStep} setStatus={setStatus} />
            </CSSTransition>

            <CSSTransition
                in={step === 4}
                timeout={{ enter: 100, exit: 100 }}
                classNames={{
                    enter: 'slide-enter',
                    enterActive: 'slide-enter-active',
                    exit: 'slide-exit',
                    exitActive: 'slide-exit-active'
                }}
                unmountOnExit
            >
                <Result status={status} question = {question} />
            </CSSTransition>

        </EventLayout>
    )
}
