import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Layout from './components/Layout';
import events from "../pages/events/EventsList";
import { UserContext } from "../context/UserContext";

export default function Events() {
    const { token, role, event_id } = useContext(UserContext);
    return (
        <Layout>
            <div className='events-dashboard'>
                {(role === "admin" || role === "charity") && (
                    <>
                        <h2 className="title">Charity</h2>
                        <div className='content'>
                            {
                                events.map((el) => {
                                    if (el.charity) {
                                        return (
                                            <Link to={`/dashboard${el.path}`}>
                                                {el.doctitle.replace("Form", "")}
                                            </Link>
                                        )
                                    }
                                })
                            }
                        </div>
                    </>
                )}
                <h2 className="title">Events</h2>
                <div className="content" style={{ margin: "0 0 22px 0" }}>
                    {
                        (role === "admin" || (role === "pm" && event_id.indexOf("5-17") !== -1) || (role === "technical" && event_id.indexOf("5-17") !== -1))  && (
                            <>
                                <Link to = {`/dashboard/newstage17`}>New Stage17</Link>
                                <Link to = {`/dashboard/newstage17interviews`}>New Stage17 Interviews</Link>
                                <Link to={`/dashboard/newstage17attendance`}>New Stage17 Attendance</Link>
                            </>
                        )
                    }
                    {
                        (role === "admin" || (role === "pm" && event_id.indexOf("8-16") !== -1)) && (
                            <>
                                <Link to={`/dashboard/recruitment23`}>Recruitment23</Link>
                            </>
                        )
                    }
                    {
                        events.reverse().map((el) => {
                            if (!el.charity && !el.updateForm)
                                if (role === "admin" || (role === "pm" && event_id.indexOf(el.eventid) !== -1) || (role === "technical" && event_id.indexOf(el.eventid) !== -1))
                                    if (el.attendance) {
                                        return (
                                            <>
                                                <Link to={`/dashboard${el.path}`} >{el.path.replace("/", "")}</Link>
                                                <Link to={`/dashboard/attendance${el.path}`}>{el.path.replace("/", "")} attendance</Link>
                                            </>
                                        )
                                    } else {
                                        return (
                                            <Link to={`/dashboard${el.path}`}>{el.path.replace("/", "")}</Link>
                                        )
                                    }
                        })
                    }
                    {
                        (role === "admin" || (role === "pm" && event_id.indexOf("3-2") !== -1) || (role === "technical" && event_id.indexOf("3-2") !== -1)) && (
                            <>
                                <Link to={`/dashboard/desertmaze`} >Desert Maze</Link>
                                <Link to={`/dashboard/attendance/desertmaze`}>Desert Maze attendance</Link>
                            </>
                        )
                    }
                    {
                        (role === "admin" || (role === "pm" && event_id.indexOf("5-16") !== -1) || (role === "technical" && event_id.indexOf("5-16") !== -1)) && (
                            <>
                                <Link to={`/dashboard/newstage16`}>New Stage 16</Link>
                                <Link to={`/dashboard/attendance/newstage16`}>New Stage 16 Attendance</Link>
                                <Link to={`/dashboard/interview/newstage16`}>New Stage 16 Interviews</Link>
                                <Link to={`/dashboard/feedback/newstage16`}>New Stage 16 Feedback</Link>
                            </>
                        )
                    }
                    {
                        role === "admin" && (
                            <>
                                <Link to={`/dashboard/aicatalyst`}>AICatalyst</Link>
                                <Link to={`/dashboard/attendance/aicatalyst`}>AICatalyst attendance</Link>
                            </>
                        )
                    }
                    {
                        (role === "admin" || (role === "pm" && event_id.indexOf("2-3") !== -1)) && (
                            <>
                                <Link to={`/dashboard/problemsolving23/points`}>PST points</Link>
                            </>
                        )
                    }
                    {
                        (role === "admin" || (role === "pm" && event_id.indexOf("8-16") !== -1)) && (
                            <>
                                <Link to={`/dashboard/interview/recruitment23`}>Recruitment 23 Interviews</Link>
                            </>
                        )
                    }
                    {
                        (role === "admin" || (role === "pm" && event_id.indexOf("7-11") !== -1)) && (
                            <>
                                <Link to={`/dashboard/startcourse23/points`}>Start Course 23 Points</Link>
                            </>
                        )
                    }
                </div>
            </div>
        </Layout>
    )
}
