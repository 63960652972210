import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router";
import sideimg from "../img/logo.png";
import Navbar from "../components/Navbar";
import Layout from "../components/Layout";
import Footer from "../components/Footer";
import dateFormat from "dateformat";
import MarkdownPreview from "@uiw/react-markdown-preview";
import BlogDetailsCard from "../components/BlogDetailsCard";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  FacebookMessengerShareButton,
  WhatsappShareButton,
} from "react-share";

export default function BlogPage() {
  const [loading, setLoading] = useState(true);
  const [blog, setBlog] = useState([]);
  const [blogs, setBlogs] = useState([]);
  let [suggestedBlogs, setSuggestedBlogs] = useState([]);
  const { id } = useParams();
  const history = useHistory();
  useEffect(() => {
    document.title = "180Daraga | Blogs";
    axios
      .get(`/api/blogs/${id}`)
      .then((response) => {
        if (response.data.message.length === 0) history.push("/404");
        setBlog(response.data.message[0]);
        axios
          .get(`/api/blogs/all/published`)
          .then((res) => {
            let latest = res.data.message.filter((el) => el.id != id);
            latest = latest.slice(0, Math.min(latest.length, 5));
            setBlogs(latest);
            let categories = response.data.message[0].categories.split(",");
            let filteredBlogs = res.data.message.filter((el) => {
              let found = false;
              if (el.id != id) {
                categories.map(element => {
                  if (el.categories.indexOf(element) != -1) found = true;
                });
              }
              return found;
            })
            let suggested = [];
            while (suggested.length < 5 && filteredBlogs.length) {
              let i = Math.floor(Math.random() * filteredBlogs.length);
              suggested.push(filteredBlogs[i]);
              filteredBlogs.splice(i, 1);
            }
            setSuggestedBlogs(suggested);
            setLoading(false);
          })
          .catch((err) => toast.error("Something wrong happend!"));
      })
      .catch((err) => toast.error("Something wrong happend!"));
  }, []);

  const headerprops = {
    maintext: "180 BLOGS",
    sideimg: sideimg,
    backgroundimg: loading ? "" : `${axios.defaults.baseURL}/public/${blog.cover}`,
    home: false,
    slogan: "The official 180 Daraga blogs !",
  };

  return (
    <Layout className="blog-page">
      {/* <Header {...headerprops} /> */}
      {
        !loading && (
          <>
            <Navbar />
            <div className="container">
              <div className="content" style={{ gridTemplateColumns: `${blogs.length == 0 ? "1fr" : ""}`}}>
                {
                  blogs.length != 0 && (
                    <div className="blogs">
                      <h2>أضيف مؤخراً</h2>
                      <div className="recently-added">
                        {blogs.map((el) => {
                          return (
                            <BlogDetailsCard
                              cover={el.cover}
                              title={el.title}
                              body={el.plain_text}
                              date={el.date}
                              id={el.id}
                            />
                          );
                        })}
                      </div>
                      {
                        suggestedBlogs.length != 0 && (
                          <>
                            <h2>المقالات المقترَحة</h2>
                            <div className="recommended">
                              {suggestedBlogs.map((el) => {
                                return (
                                  <BlogDetailsCard
                                    cover={el.cover}
                                    title={el.title}
                                    date={el.date}
                                    id={el.id}
                                  />
                                );
                              })}
                            </div></>
                        )
                      }
                    </div>
                  )
                }
                <div className="blog">
                  <img src={`${axios.defaults.baseURL}/public/${blog.cover}`} alt="" />
                  <div className="title">
                    <h1>{blog.title}</h1>
                    <p className="date">{dateFormat(Date.parse(blog.date), "dddd, mmmm dS, yyyy")}</p>
                    <div className="share">
                      <div className="share-btn">
                        <FacebookShareButton url={window.location.href}><FacebookIcon size={40} round={true} /></FacebookShareButton>
                      </div>
                      <div className="share-btn">
                        <WhatsappShareButton url={window.location.href}><WhatsappIcon size={40} round={true} /></WhatsappShareButton>
                      </div>
                      <div className="share-btn">
                        <FacebookMessengerShareButton url={window.location.href}><FacebookMessengerIcon size={40} round={true} /></FacebookMessengerShareButton>
                      </div>
                      <div className="share-btn">
                        <TwitterShareButton url={window.location.href}><TwitterIcon size={40} round={true} /></TwitterShareButton>
                      </div>
                      <div className="share-btn">
                        <TelegramShareButton url={window.location.href}><TelegramIcon size={40} round={true} /></TelegramShareButton>
                      </div>
                    </div>
                  </div>
                  <div className="body">
                    <MarkdownPreview
                      className="body"
                      source={blog.body}
                      wrapperElement={{
                        "data-color-mode": "light",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </>
        )
      }
    </Layout>
  );
}
