import React, { useState, useEffect, useRef } from "react";
import EventLayout from "../../components/EventLayout";
import swal from "sweetalert";
import axios from "axios";

export default function FeedbackMain(props) {
  const headerprops = {
    maintext: props.headerconfig.maintext,
    backgroundimg: props.headerconfig.backgroundimg,
    slogan: props.headerconfig.slogan,
  };

  const [formData, setFormData] = useState();
  const [disabled, setDisabled] = useState(false);


  useEffect(() => {
    document.title = `180Daraga | ${props.doctitle}`;
  }, [props.doctitle]);

  const handleChange = (e) => {
    console.log(e.target.name);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);
      axios
        .post(`${props.api}`, formData)
        .then((response) => {
          if (response.data.status) {
              swal({
                title: "Thank You!",
                text: "Your feedback has been sent successfully",
                icon: "success",
              });
              setDisabled(false);
          } else {
            swal({
              title: "Oops!",
              text: "Something went wrong\n",
              icon: "error",
            });
            setDisabled(false);
          }
        })
        .catch((err) => {
          swal({ title: "Error 😔", text: "Something wrong happend!", icon: "error" });
          setDisabled(false);
        });

  };

  return (
    <>
      {true && (
        <EventLayout headerconfig={headerprops}>
          {props.cautions && (
            <div className="cautions">
              <h3 className="cautions-title">YOU MATTER AND YOUR FEEDBACK MATTERS</h3>
              {props.cautions.split("\n").map((caution, key) => {
                return (
                  <p key={key} style={{ marginTop: "20px" }}>
                    {caution}
                  </p>
                );
              })}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            {Object.keys(props.datafields).map((key, val) => {
              if (typeof props.datafields[key] === "object") {
                return (
                  <div className="input-group" key={val}>
                    <label htmlFor={`${key}`}>{props.datafieldsname[key]}</label>
                    <select name={`${key}`} id={`${key}`} onChange={handleChange} required>
                      <option value={""}>Select an option:</option>
                      {Array.isArray(props.datafields[key]) &&
                        props.datafields[key].map((e, val) => {
                          return (
                            <option value={`${e}`} key={val}>
                              {e}
                            </option>
                          );
                        })}
                      {
                        //formData[`${props.datafieldsdep[key]}`] === val)

                        !Array.isArray(props.datafields[key]) &&
                          Object.keys(props.datafields[key]).map((ke, vall) => {
                            if (formData[`${props.datafieldsdep[key]}`] === ke) {
                              return props.datafields[key][ke].map((kk, vv) => {
                                return (
                                  <option value={`${kk}`} key={vv}>
                                    {kk}
                                  </option>
                                );
                              });
                            }
                          })
                      }
                    </select>
                  </div>
                );
              }else if(props.datafields[key]=="textarea")
              {
                return(
                  <div className="input-group" key={val}>
                    <label htmlFor={`${key}`}>{props.datafieldsname[key]}</label>
                    <textarea onChange={handleChange} name={key} id={key}>
                      </textarea>
                  </div>
                );
              } 
              else {
                  return (
                    <div className="input-group" key={val}>
                      <label htmlFor={`${key}`}>{props.datafieldsname[key]}</label>
                      <input
                        type={`${props.datafields[key]}`}
                        name={key}
                        id={key}
                        onChange={handleChange}
                      />
                    </div>
                  );
              }
            })}

            <button className="btn btn-normal" disabled={disabled}>
              submit
            </button>
          </form>
        </EventLayout>
      )}
    </>
  );
}
