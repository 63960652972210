import React, { useState, useEffect, useRef } from "react";
import EventLayout from "../../components/EventLayout";

import swal from "sweetalert";
import axios from "axios";

import { useQrCode } from "../../QrCode";

export default function EventMain(props) {
  const headerprops = {
    maintext: props.headerconfig.maintext,
    backgroundimg: props.headerconfig.backgroundimg,
    slogan: props.headerconfig.slogan,
  };

  const [formData, setFormData] = useState({ sendMail: props.sendMail, noRepeat: props.noRepeat });
  const [disabled, setDisabled] = useState(false);

  const [qrCode, setQrCode] = useQrCode(null);
  const [showQR, setShowQR] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    document.title = `180Daraga | ${props.doctitle}`;
  }, [props.doctitle]);

  const handleChange = (e) => {
    if (e.target.name === "email") {
      if (e.target.value.indexOf("‏") !== -1) {
        e.target.value = e.target.value.slice(0, e.target.value.lastIndexOf("‏"));
      }
    }
    let flag = 1;
    if (props.datafieldsdep) {
      Object.keys(props.datafieldsdep).map((i) => {
        console.log(props.datafieldsdep[i] === e.target.name);
        if (props.datafieldsdep[i] === e.target.name && flag) {
          flag = 0;
          setFormData({ ...formData, [i]: "", [e.target.name]: e.target.value });
        }
      });
    }
    if (flag) setFormData({ ...formData, [e.target.name]: e.target.value });
    console.log(formData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);

    if (formData.phone) {
      console.log(formData.phone);
      var phoneNo = /^\d{11}$/;
      if (!formData.phone.match(phoneNo)) {
        swal({
          title: "Error 😔",
          text: "Please enter a valid phone number\nIn likes of 01XXXXXXXXX",
          icon: "error",
        });
        setDisabled(false);
        return;
      }
    }

    // form validation
    let check = true;
    Object.keys(props.datafields).map((el) => {
      if (
        Object.keys(formData).indexOf(el) === -1 ||
        Object.values(formData)[Object.keys(formData).indexOf(el)] === ""
      ) {
        swal({
          title: "Oops!",
          text: "Please fill out all required fields\n",
          icon: "error",
        });
        setDisabled(false);
        check = false;
      }
    });
    if (!check) return;

    if (!props.updateForm)
      axios
        .post(`${props.api}`, formData)
        .then((response) => {
          if (response.data.status) {
            if (props.QRcode) {
              setShowQR(true);
              setQrCode(response.data.message);
              qrCode.append(ref.current);
            } else {
              swal({
                title: "Thank You!",
                text: "You registered successfully",
                icon: "success",
              });
            }
          } else {
            swal({
              title: "Oops!",
              text: "Something went wrong\n",
              icon: "error",
            });
            setDisabled(false);
          }
        })
        .catch((err) => {
          swal({ title: "Error 😔", text: "Something wrong happend!", icon: "error" });
          setDisabled(false);
        });
    else {
      axios
        .patch(`${props.api}`, { ...formData, constraint: props.constraint })
        .then((response) => {
          if (response.data.status) {
            if (props.QRcode) {
              setShowQR(true);
              setQrCode(response.data.message);
              qrCode.append(ref.current);
            } else {
              swal({
                title: "Thank You!",
                text: "You registered successfully",
                icon: "success",
              });
              setDisabled(false);
            }
          } else {
            console.log(response.data);
            swal({
              title: "Oops!",
              text: "Something went wrong\n",
              icon: "error",
            });
            setDisabled(false);
          }
        })
        .catch((err) => {
          swal({ title: "Error 😔", text: "Something wrong happend!", icon: "error" });
          setDisabled(false);
        });
    }
  };

  return (
    <>
      {!showQR && (
        <EventLayout headerconfig={headerprops} closed={props.closed}>
          {props.cautions && (
            <div className="cautions">
              <h3>Cautions</h3>
              {props.cautions.split("\n").map((caution, key) => {
                return (
                  <p key={key} style={{ marginTop: "20px" }}>
                    {caution}
                  </p>
                );
              })}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            {
              //Make loop around all datafields
            }
            {Object.keys(props.datafields).map((key, val) => {
              // if typeof datafield is array or object create a select box
              if (typeof props.datafields[key] === "object") {
                return (
                  <div className="input-group" key={val}>
                    {
                      // if it is an array make a normal select box
                      Array.isArray(props.datafields[key]) && (
                        <>
                          <label htmlFor={`${key}`}>{props.datafieldsname[key]}</label>
                          <select name={`${key}`} id={`${key}`} onChange={handleChange} required>
                            <option value={""}>Select an option:</option>
                            {props.datafields[key].map((e, val) => {
                              return (
                                <option value={`${e}`} key={val}>
                                  {e}
                                </option>
                              );
                            })}
                          </select>
                        </>
                      )
                    }
                    {
                      //formData[`${props.datafieldsdep[key]}`] === val)

                      // if it is an object make a select box dependent of another field

                      (!Array.isArray(props.datafields[key]) &&
                        formData[`${props.datafieldsdep[key]}`] &&
                        typeof props.datafields[key][formData[`${props.datafieldsdep[key]}`]] ===
                          "string" && (
                          <>
                            <div className="input-group" key={val}>
                              <label htmlFor={`${key}`}>{props.datafieldsname[key]}</label>
                              <input
                                type={`${props.datafields[key]}`}
                                name={key}
                                id={key}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </>
                        )) ||
                        (!Array.isArray(props.datafields[key]) &&
                          Object.keys(props.datafields[key]).map((ke, vall) => {
                            console.log(formData[`${props.datafieldsdep[key]}`] + " " + ke);
                            if (formData[`${props.datafieldsdep[key]}`] === ke)
                              return (
                                <>
                                  <label htmlFor={`${key}`}>{props.datafieldsname[key]}</label>
                                  <select name={`${key}`} id={`${key}`} onChange={handleChange}>
                                    <option value={""}>Select an option:</option>
                                    {props.datafields[key][ke].map((kk, vv) => {
                                      return (
                                        <option value={`${kk}`} key={vv}>
                                          {kk}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </>
                              );
                          }))
                    }
                  </div>
                );
              }
              // if not object then make a normal input field, with the value inside datafield to be the type of input
              else {
                return (
                  <div className="input-group" key={val}>
                    <label htmlFor={`${key}`}>{props.datafieldsname[key]}</label>
                    <input
                      type={`${props.datafields[key]}`}
                      name={key}
                      id={key}
                      onChange={handleChange}
                    />
                  </div>
                );
              }
            })}
            <button className="btn btn-normal" disabled={disabled}>
              Submit
            </button>
          </form>
        </EventLayout>
      )}
      {showQR && (
        <EventLayout headerconfig={headerprops}>
          <div className="QRcodeContainer" style={{ textAlign: "center", margin: "30px" }}>
            <div className="QRcodeContainerText">
              <p style={{ fontSize: "30px", margin: "20px" }}>Thank You!</p>
              <p style={{ fontSize: "20px", margin: "20px" }}>
                Please keep this QRcode somewhere safe, as you will need it to attend the event
              </p>
            </div>
            <div ref={ref} />
          </div>
        </EventLayout>
      )}
    </>
  );
}

/*

                          if (
                            !props.datafields[key].hasOwnProperty(
                              formData[`${props.datafieldsdep[key]}`]
                            )
                          ) {
                            return (
                              <>
                                <label htmlFor={`${key}`}>{props.datafieldsname[key]}</label>
                                <input
                                  type={`${props.datafields[key]}`}
                                  name={key}
                                  id={key}
                                  onChange={handleChange}
                                  required
                                />
                              </>
                            );
                          }
*/
