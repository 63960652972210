import React from "react";
import president from "../../img/highboard2024/Hajar Yasser.jpeg";
import cooCairo from "../../img/highboard2024/Hazem Medhat.jpeg";
import viceCairo from "../../img/highboard2024/Yousef Alaa.jpeg";
import cooHelwan from "../../img/highboard2024/Shahdan.jpeg";
import viceGeneral from "../../img/highboard2024/Mohamed Ashraf.jpeg";
import general from "../../img/highboard2024/Nada Mohamed.jpeg";

export default function Highboard() {
  return (
    <section className="section highboard">
      <div className="container">
        <h2>Highboard</h2>
        <div className="presedent">
          <img src={president} alt="" />
          <h3>Hajar Yasser</h3>
          <p>president</p>
        </div>

        <div className="coordinators">
          <div className="coordinator">
            <img src={cooCairo} alt="" />
            <h3>Hazem Medhat</h3>
            <p>Cairo coordinator</p>
          </div>
          <div className="coordinator">
            <img src={viceCairo} alt="" />
            <h3>Yousef Alaa</h3>
            <p>Vice cairo coordinator</p>
          </div>
          <div className="coordinator">
            <img src={cooHelwan} alt="" />
            <h3>Shahdan Hegazy</h3>
            <p>Helwan coordinator</p>
          </div>
          <div className="coordinator">
            <img src={general} alt="" />
            <h3>Nada Mohamed</h3>
            <p>General coordinator</p>
          </div>
          <div className="coordinator">
            <img src={viceGeneral} alt="" />
            <h3>Mohamed Ashraf</h3>
            <p>Vice General coordinator</p>
          </div>
        </div>
      </div>
    </section>
  );
}
