import React, { useState } from 'react'
import axios from 'axios';
import swal from 'sweetalert';
export default function Form({setStep}) {
    const [formData, setFormData] = useState({});
    const [disabled, setDisabled] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        setDisabled(true);
        var phoneNo = /^\d{11}$/;
        if (!formData.phone.match(phoneNo)) {
            swal({
                title: "Error 😔",
                text: "Please enter a valid phone number\nIn likes of 01XXXXXXXXX",
                icon: "error",
            });
            setDisabled(false);
            return;
        }
        try {
            const response = axios.post(`/api/events/eventsmain/10-2`, formData);
            setStep(2);
            console.log(formData);
            console.log("done")
        } catch (err) {
            swal({ title: "Error 😔", text: "Something wrong happend!", icon: "error" });
            setDisabled(false);
        }

    };
    const handleChange = (e) => {
        if (e.target.name === "email") {
            if (e.target.value.indexOf("‏") !== -1) {
                e.target.value = e.target.value.slice(0, e.target.value.lastIndexOf("‏"));
            }
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
        console.log(formData);
    };
    return (
        <form onSubmit={handleSubmit} style={{ padding: "20px 0" }}>
            <div className="input-group" key="name">
                <label htmlFor="name">Full Name</label>
                <input
                    type="text"
                    name="name"
                    id="name"
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="input-group" key="email">
                <label htmlFor="email">Email</label>
                <input
                    type="email"
                    name="email"
                    id="email"
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="input-group" key="phone">
                <label htmlFor="phone">Phone Number</label>
                <input
                    type="text"
                    name="phone"
                    id="phone"
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="input-group">
                <label htmlFor="university">Faculty</label>
                <select
                    name="university"
                    id="university"
                    onChange={handleChange}
                    required
                >
                    <option value={""}>Select an option:</option>
                    <option value="cs">
                        CS - Helwan
                    </option>
                    <option value="engineering">
                        Engineering - Helwan
                    </option>
                    <option value="science">
                        Science - Helwan
                    </option>
                </select>
            </div>
            {
                formData.university && (

                    <div className="input-group">
                        <label htmlFor="university_level">Level</label>
                        <select
                            name="university_level"
                            id="university_level"
                            onChange={handleChange}
                            required
                        >
                            <option value={""}>Select an option:</option>
                            <option value="1" key="1">
                                1
                            </option>
                            <option value="2" key="2">
                                2
                            </option>
                            <option value="3" key="3">
                                3
                            </option>
                            <option value="4" key="4">
                                4
                            </option>
                            {
                                formData.university === "engineering" && (
                                    <option value="5" key="5">
                                        5
                                    </option>
                                )
                            }
                            <option value="grad" key="grad">
                                Grad
                            </option>
                        </select>
                    </div>
                )
            }
            <button className="btn btn-normal" disabled={disabled}>
                submit
            </button>
        </form>
    )
}
