import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useCountUp } from "react-countup";

export default function Counters() {
  const [ref, inView] = useInView({
    threshold: 0.2,
  });
  const animation = useAnimation();
  const membersCounter = useCountUp({
    start: 0,
    end: 200,
    duration: 3,
  });
  const eventsCounter = useCountUp({
    start: 0,
    end: 120,
    duration: 3,
  });
  const runningEventsCounter = useCountUp({
    start: 0,
    end: 3,
    duration: 1,
  });

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        transition: {
          duration: 1,
        },
      });
      membersCounter.start();
      eventsCounter.start();
      runningEventsCounter.start();
    } else {
      animation.start({
        opacity: 0,
      });
    }
  }, [inView, animation]);

  return (
    <section ref={ref} className="section counters">
      <div className="container">
        <motion.div animate={animation} className="col">
          <span>+{membersCounter.countUp}</span>
          <p>Members</p>
        </motion.div>
        <motion.div animate={animation} className="col">
          <span>+{eventsCounter.countUp}</span>
          <p>Events</p>
        </motion.div>
        <motion.div animate={animation} className="col">
          <span>{runningEventsCounter.countUp}</span>
          <p>Running Events</p>
        </motion.div>
      </div>
    </section>
  );
}
