import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import axios from "axios";
import ReactStars from "react-rating-stars-component";
import GeneralFeed from "./GeneralFeed";
import DayFeed from "./DayFeed";
import EventLayout from "../../components/EventLayout";
import newstage16Cover from "../../img/newstage16.jpg";
import day1 from "../../img/events/newstage16Agenda/day1.jpg";
import day2 from "../../img/events/newstage16Agenda/day2.jpg";
import day3 from "../../img/events/newstage16Agenda/day3.jpg";
import day4 from "../../img/events/newstage16Agenda/day4.jpg";
import day5 from "../../img/events/newstage16Agenda/day5.jpg";

function NewStageFeedback() {
  const [state, setState] = useState(0);
  const [name, setName] = useState("");
 
  const headerprops = {
    maintext: "NewStage16",
    backgroundimg: newstage16Cover,
    slogan: "NewStage16 IS UNDER THE WAY!!",
  };

  const handleState = (state) => {
    setState(state + 1);
    // console.log(setState);
  };


  return (
    <EventLayout headerconfig={headerprops}>
      <>
        {state === 0 && <GeneralFeed handleState={handleState} setName={setName} />}
        {state === 1 && <DayFeed day={1} agenda={day1} handleState={handleState} name={name} />}
        {state === 2 && <DayFeed day={2} agenda={day2} handleState={handleState} name={name} />}
        {state === 3 && <DayFeed day={3} agenda={day3} handleState={handleState} name={name} />}
        {state === 4 && <DayFeed day={4} agenda={day4} handleState={handleState} name={name} />}
        {state === 5 && <DayFeed day={5} agenda={day5} handleState={handleState} name={name} />}
        {state === 6 && (
          <div style={{ textAlign: "center", margin: "30px" }}>
            <p style={{ fontSize: "30px", margin: "20px" }}>Thank you for your feedback!</p>
            <p style={{ fontSize: "20px", margin: "20px" }}>
              Hope to see you again in our next events ❤️
            </p>
            <b style={{color: "#700608", fontSize: "25px"}}>180 Daraga Team</b>
          </div>
        )}
      </>
    </EventLayout>
  );
}

export default NewStageFeedback;
