import React from 'react'
import { FaFacebookF, FaYoutube, FaTwitter, FaInstagram } from 'react-icons/fa';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__left">
          <p>copyright &copy; 2020, 180Daraga. All Rights Reserved</p>
          <ul>
            <li><a href="https://www.facebook.com/180.Daraga" target="_blank" rel="noreferrer" className="facebook"><FaFacebookF /></a></li>
            <li><a href="https://www.youtube.com/channel/UCy2pNwCwdCDBa557YSYzLOA" target="_blank" rel="noreferrer" className="youtube"><FaYoutube /></a></li>
            <li><a href="https://twitter.com/180daraga" target="_blank" rel="noreferrer" className="twitter"><FaTwitter /></a></li>
            <li><a href="https://www.instagram.com/180daraga" target="_blank" rel="noreferrer" className="instagram"><FaInstagram /></a></li>
          </ul>
        </div>
        <div className="footer__right">
          <p>Email: <a href="mailto: 180daraga.org@gmail.com">180daraga.org@gmail.com</a></p>
          <p>Tel: <a href="tel: +20 115 652 8527">+20 115 652 8527</a></p>
        </div>
      </div>
    </footer>
  )
}
