import React from 'react'

function HelwanButtons({setChoice , setStep}) {
  const handleChoice = (e) => {
    setChoice(e.target.value);
    setStep(3);
  }
  return (
    <div className="event-buttons">
    <h1 className="wobble">Lets Play🃏</h1>
    <h2>Choose❓</h2>
    <div className="button-group">
        <button style={{
              marginRight:"47px",
              backgroundColor: "#F99533",
        }}className="hevent-button b1" value={1} onClick={handleChoice}>
            1
        </button>
        <button style={{marginRight:"47px", backgroundColor : "#24CA69"}} className="hevent-button" value={2} onClick={handleChoice}>
            2
        </button>
        <button  style={{backgroundColor : "#46AEFF"}}className="hevent-button" value={3} onClick={handleChoice}>
            3
        </button>
    </div>
</div>
  )
}

export default HelwanButtons