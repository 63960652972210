import React from "react";
import Home from "./pages/Home";
import Team from "./pages/Team";
import Events from "./pages/Events";
import Login from "./pages/Login";
import Reset from "./pages/Reset";
import NotFound from "./pages/NotFound";
import Dashboard from "./admin/Dashboard";
import Users from "./admin/Users";
import AddUser from "./admin/AddUser";
import ProtectedRoute from "./components/ProtectedRoute";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AnimatePresence } from "framer-motion";
import Database from "./admin/Database";
import ChangePassword from "./admin/ChangePassword";
import DesertMaze from "./pages/events/DesertMaze";
import EventMain from "./pages/events/EventMain";
import EventsList from "./pages/events/EventsList";
import EventFormDashboard from "./admin/EventFormDashboard";
import FeedbackMain from "./pages/events/FeedbackMain";
import EventsDashboard from "./admin/Events";
import AddBlog from "./admin/AddBlog";
import AddCategory from "./admin/AddCategory";
import Categories from "./admin/Categories";
import BlogsDashboard from "./admin/BlogsDashboard";
import Blogs from "./pages/BlogsMain";
import BlogPage from "./pages/BlogPage";
import EditBlog from "./admin/EditBlog";
import AICatalyst from "./pages/events/AICatalyst";
import NewStage16 from "./pages/events/NewStage16";
import NewStage16Interviews from "./admin/NewStage16Interviews";
import NewStage16Feedback from "./pages/events/NewStageFeedback";
import Recruitment23 from "./pages/events/Recruitment23";
import Recruitment23FirstInterview from "./admin/Recruitment23FirstInterview";
import MirageZone from "./pages/events/MirageZone";
import HelwanActivity from "./pages/events/HelwanActivity";
import NewStage17 from "./pages/events/NewStage17";
import NewStage17Interviews from "./admin/NewStage17Interviews";
import Recruitment24 from "./pages/events/Recruitment24";


export default function App() {
  const location = useLocation();

  //To hide any unnoticed logs in the code, to be removed during devlemopment
  console.log = () => {
    return 1;
  };

  const AICatalystDataFields = {
    person_id: "text",
    name: "text",
    email: "email",
    phone: "text",
    data1: "text",
    university: ["High school", "College student", "Grad"],
    data2: "text",
    data3: "text",
    comment: "text",
  };
  const AICatalystDataFieldsName = {
    person_id: "ID",
    name: "Full Name",
    email: "Email",
    phone: "Phone Number",
    data1: "National ID",
    university: "Grade",
    data2: "School/College Name",
    data3: "Major",
    comment: "Type",
  };

  let desertMazeDataFields = {
    team_name: "text",
    first_member_name: "text",
    first_member_phone: "text",
    first_member_email: "email",
    first_member_faculty: ["cs", "engineering", "science"],
    first_member_level: ["1", "2", "3", "4", "5"],
    second_member_name: "text",
    second_member_phone: "text",
    second_member_email: "email",
    second_member_faculty: ["cs", "engineering", "science"],
    second_member_level: ["1", "2", "3", "4", "5"],
    third_member_name: "text",
    third_member_phone: "text",
    third_member_email: "email",
    third_member_faculty: ["cs", "engineering", "science"],
    third_member_level: ["1", "2", "3", "4", "5"],
  };

  let desertMazeDataFieldsName = {
    team_id: "Team ID",
    team_name: "Team Name",
    first_member_name: "FM Name",
    first_member_phone: "FM Phone",
    first_member_email: "FM Email",
    first_member_faculty: "FM Faculty",
    first_member_level: "FM Level",
    second_member_name: "SM Name",
    second_member_phone: "SM Phone",
    second_member_email: "SM Email",
    second_member_faculty: "SM Faculty",
    second_member_level: "SM Level",
    third_member_name: "TM Name",
    third_member_phone: "TM Phone",
    third_member_email: "TM Email",
    third_member_faculty: "TM Faculty",
    third_member_level: "TM Level",
  };

  const PstPointsDataFields = {
    person_id: "ID",
    name: "Name",
    data2: "Level",
    data3: "Points",
    comment: "Group",
  };

  const startCourse23DataFields = {
    person_id: "ID",
    name: "Name",
    phone: "Phone",
    data2: "Group",
    data3: "Points",
  };

  // New Stage 16 Attendence Dashboard
  const NewStage16AttendenceDataFieldsName = {
    person_id: "ID",
    name: "Name",
    phone: "Phone",
    university: "University",
    university_level: "Level",
    date: "Attended at",
  };
  // New Stage 16 Form Dashboard
  const NewStage16DataFieldsName = {
    person_id: "id",
    name: "Name",
    email: "Email",
    phone: "Phone",
    national_id: "National ID",
    university: "University",
    collage: "College",
    university_level: "Level",
    attended_before: "Attended before?",
    register_date: "Registeration Date",
    interview_time: "Interview time",
  };

  // New Stage 16 Feedback Dashboard
  const NewStage16FeedbackDataFields = {
    id: "text",
    name: "text",
    rate: "text",
    field: "text",
    feedback: "text",
  };
  const NewStage16FeedbackDataFieldsName = {
    id: "ID",
    name: "Name",
    rate: "Rate",
    field: "Day",
    feedback: "Feedback",
  };

  const NewStage16DataFields = {
    person_id: "text",
    name: "text",
    email: "email",
    phone: "text",
    national_id: "text",
    university: ["Cairo", "Ain Shams", "Helwan", "Other"],
    collage: "text",
    university_level: ["1", "2", "3", "4"],
    attended_before: ["Yes", "No"],
  };


  const NewStage17DataFields = {
    person_id: "text",
    name: "text",
    email: "text",
    age : "text",
    phone: "text",
    city : ["Cairo", "Giza", "Alex","Other"],
    district : ["Maadi", "Haram", "Dokki", "October" , "5th Settlement", "Nasr City", "Other"],
    university: ["Cairo", "Ain Shams", "Helwan", "Other"],
    collage: "text",
    university_level: ["1", "2", "3", "4","5"],
    attended_before: ["Yes", "No"] ,
    year_attended : "text",
    how_did_you_hear: ["Facebook", "Instagram", "Linkedin","Friends", "Other"],
    interview_time : "text",
    register_date : "text"
};


const NewStage17DataFieldsName = {
    person_id: "ID",
    name: "Name",
    email: "Email",
    age : "Age",
    phone: "Phone",
    city: "City",
    district: "District",
    university: "University",
    collage: "College",
    university_level: "Level",
    attended_before: "Attended New Stage Before?",
    year_attended : "In which year?" ,
    how_did_you_hear: "How did you hear about us?",
    interview_time : "Interview Time",
    register_date : "Register Date"
};

const NewStage17AttendenceDataFieldsName = {
  person_id: "ID",
  name: "Name",
  phone: "Phone",
  university: "University",
  university_level: "Level",
  date: "Attended at",
};



  const recruitment23DataFields = {
    name: "text",
    email: "email",
    phone: "text",
    university: ["Cairo ", "Ain Shams", "Helwan", "Other"],
    university_level: ["1", "2", "3", "4"],
    university_name: "text",
    first_committee: ["PR/FR", "Graphics", "Social Media Marketing", "Video", "Technical"],
    second_committee: ["PR/FR", "Graphics", "Social Media Marketing", "Video", "Technical"],
    Wave: ["2", "1"],
  };

  const recruitment23DataFieldsName = {
    person_id: "id",
    name: "Name",
    email: "Email",
    phone: "Phone",
    university: "University",
    university_level: "Level",
    university_name: "Other University Name",
    first_committee: "First Committee",
    second_committee: "Second Committee",
    Wave: "Wave",
  };


  const recruitment24DataFields = {
    name: "text",
    email: "email",
    phone: "text",
    university: ["Cairo ", "Ain Shams", "Helwan", "Other"],
    university_name: "text",
    college: "text",
    university_level: ["1", "2", "3", "4"],
    first_committee: ["PR/FR", "Graphics", "Social Media Marketing", "Video", "Technical"],
    second_committee: ["PR/FR", "Graphics", "Social Media Marketing", "Video", "Technical"],
  };

  const recruitment24DataFieldsName = {
    person_id: "id",
    name: "Name",
    email: "Email",
    phone: "Phone",
    university: "University",
    university_name: "Other University Name",
    college: "text",
    university_level: "Level",
    first_committee: "First Committee",
    second_committee: "Second Committee",
  };


  return (
    <div className="App">
      <ToastContainer />
      <AnimatePresence>
        <Switch location={location} key={location.pathname}>
          <Route path="/" exact component={Home} />
          <Route path="/events" exact component={Events} />
          <Route path="/team" exact component={Team} />
          {/* <Route path="/desertmaze" exact component={DesertMaze} />
          <Route path="/AICatalyst" exact component={AICatalyst} /> */}
          <Route path="/login" exact component={Login} />
          <Route path="/blogs" exact component={Blogs} />
          <Route path="/reset" exact component={Reset} />
          <Route path="/404" exact component={NotFound} />
          <Route path="/blogs/:id" exact component={BlogPage} />
          {/* <Route path="/newstage16" exact component={NewStage16} /> */}
          {/* <Route path="/newstage16feedback" exact component={NewStage16Feedback} /> */}
          {/* <Route path="/recruitment23" exact component={Recruitment23} /> */}
          <Route path="/miragezone" exact component={MirageZone} />
          <Route path="/helwanactivity" exact component={HelwanActivity} />
          <Route path="/newstage17" exact component={NewStage17} />
          <Route path="/recruitment24" exact component={Recruitment24} />
          

          {/* Dashboard Routes */}
          <ProtectedRoute path="/dashboard" exact component={Dashboard} />
          <ProtectedRoute path="/dashboard/database" exact component={Database} />
          <ProtectedRoute path="/dashboard/ChangePassword" exact component={ChangePassword} />
          <ProtectedRoute path="/dashboard/users" exact component={Users} adminOnly />
          <ProtectedRoute path="/dashboard/events" exact component={EventsDashboard} />
          <ProtectedRoute path="/dashboard/users/add" exact component={AddUser} adminOnly />
          <ProtectedRoute path="/dashboard/blogs" exact component={BlogsDashboard} smmOnly />
          <ProtectedRoute path="/dashboard/blogs/add" exact component={AddBlog} smmOnly />
          <ProtectedRoute path="/dashboard/blogs/edit/:id" exact component={EditBlog} smmOnly />
          <ProtectedRoute path="/dashboard/Categories" exact component={Categories} smmOnly />
          <ProtectedRoute path="/dashboard/Categories/add" exact component={AddCategory} smmOnly />
          <ProtectedRoute path={`/dashboard/desertmaze`} key="desertMaze" event_id="3-2" pmOnly>
            <EventFormDashboard
              doctitle="Desert Maze Form"
              datafields={desertMazeDataFields}
              datafieldsname={desertMazeDataFieldsName}
              api="/api/events/desertmaze/3-2"
              apipatchendpoint="/api/events/desertmaze"
              apideleteendpoint="/api/events/desertmaze"
            />
          </ProtectedRoute>
          <ProtectedRoute
            path={`/dashboard/problemsolving23/points`}
            key="PST"
            event_id="2-3"
            pmOnly
          >
            <EventFormDashboard
              doctitle="PST points"
              datafields={PstPointsDataFields}
              datafieldsname={PstPointsDataFields}
              api="/api/events/eventsmain/2-3"
              apipatchendpoint="/api/events/eventsmain/all/2-3"
              apideleteendpoint="/api/events/eventsmain/2-3"
            />
          </ProtectedRoute>
          <ProtectedRoute
            path={`/dashboard/startcourse23/points`}
            key="Startcourse23"
            event_id="7-11"
            pmOnly
          >
            <EventFormDashboard
              doctitle="Start Course Points"
              datafields={startCourse23DataFields}
              datafieldsname={startCourse23DataFields}
              api="/api/events/eventsmain/7-11"
              apipatchendpoint="/api/events/eventsmain/all/7-11"
              apideleteendpoint="/api/events/eventsmain/7-11"
            />
          </ProtectedRoute>
          <ProtectedRoute
            path={`/dashboard/attendance/desertmaze`}
            key="desertmaze3-2"
            pmOnly
            event_id="3-2"
          >
            <EventFormDashboard
              doctitle="Desert Maze Form attendance"
              datafields={{ ...desertMazeDataFields }}
              datafieldsname={{ ...desertMazeDataFieldsName, date: "Attended at" }}
              eventid="3-2"
              api="/api/events/desertmaze/attendance/3-2"
            />
          </ProtectedRoute>
          <ProtectedRoute path={`/dashboard/aicatalyst`} key="AICatalyst" adminOnly event_id="10-1">
            <EventFormDashboard
              doctitle="AICatalyst Form"
              datafields={{ ...AICatalystDataFields }}
              datafieldsname={{ ...AICatalystDataFieldsName }}
              eventid="10-1"
              api="/api/events/eventsmain/10-1"
              apipatchendpoint="/api/events/eventsmain/all/10-1"
              apideleteendpoint="/api/events/eventsmain/10-1"
            />
          </ProtectedRoute>
          <ProtectedRoute
            path={`/dashboard/attendance/aicatalyst`}
            key="AICatalyst"
            adminOnly
            event_id="10-1"
          >
            <EventFormDashboard
              doctitle="AICatalyst Form attendance"
              datafields={{ ...AICatalystDataFields }}
              datafieldsname={{ ...AICatalystDataFieldsName, date: "Attended at" }}
              eventid="10-1"
              attendance="true"
              api="/api/events/eventsmain/attendance/10-1"
            />
          </ProtectedRoute>
          <ProtectedRoute path={`/dashboard/newstage16`} key="newstage16" pmOnly event_id="5-16">
            <EventFormDashboard
              doctitle="New Stage 16"
              datafields={{ ...NewStage16DataFields }}
              datafieldsname={{ ...NewStage16DataFieldsName }}
              eventid="5-16"
              api="/api/events/newStage16/all/5-16"
              apipatchendpoint="/api/events/newStage16/5-16"
              apideleteendpoint="/api/events/newStage16/5-16"
              QRcode={true}
            />
          </ProtectedRoute>

          <ProtectedRoute
            path={`/dashboard/attendance/newstage16`}
            key="newstage5-16"
            event_id="5-16"
            pmOnly
          >
            <EventFormDashboard
              doctitle="New Stage Form attendance"
              datafields={{ ...NewStage16DataFields }}
              datafieldsname={{ ...NewStage16AttendenceDataFieldsName }}
              eventid="5-16"
              api="/api/events/newStage16/attendance/5-16"
            />
          </ProtectedRoute>

          <ProtectedRoute
            path={`/dashboard/recruitment23`}
            key="Recruitment23"
            event_id="8-16"
            pmOnly
          >
            <EventFormDashboard
              doctitle="Recruitment23 Form"
              datafields={recruitment23DataFields}
              datafieldsname={recruitment23DataFieldsName}
              eventid="8-16"
              api="/api/events/recruitment23"
              apipatchendpoint="/api/events/recruitment23"
              apideleteendpoint="/api/events/recruitment23"
              QRcode={true}
            />
          </ProtectedRoute>

          <ProtectedRoute
            path={`/dashboard/interview/newstage16`}
            key="newstage16Interviews"
            event_id="5-16"
            pmOnly
          >
            <NewStage16Interviews />
          </ProtectedRoute>

          <ProtectedRoute
            path={`/dashboard/feedback/newstage16`}
            key="newstagefeedback"
            event_id="5-16"
            pmOnly
          >
            <EventFormDashboard
              doctitle="New Stage Feedback"
              datafields={{ ...NewStage16FeedbackDataFields }}
              datafieldsname={{ ...NewStage16FeedbackDataFieldsName }}
              eventid="5-16"
              api="/api/events/newStage16/all/5-16/feedback"
            />
          </ProtectedRoute>

          <ProtectedRoute
            path={`/dashboard/interview/recruitment23`}
            key="recruitment23Interviews"
            event_id="8-16"
            pmOnly
          >
            <Recruitment23FirstInterview />
          </ProtectedRoute>

          <ProtectedRoute
            path={`/dashboard/newstage17`}
            key="newstage17"
            event_id="5-17"
            pmOnly
          >
            <EventFormDashboard 
                doctitle="New Stage 17"
                datafields={{ ...NewStage17DataFields }}
                datafieldsname={{ ...NewStage17DataFieldsName }}
                eventid="5-17"
                api="/api/event/newstage17"
                apipatchendpoint="/api/event/newstage17"
                apideleteendpoint="/api/event/newstage17"
                QRcode={true}
                apiforgetqr = "/api/event/newstage17/sendmail/qr"
            />
          </ProtectedRoute>

          <ProtectedRoute
            path = {`/dashboard/newstage17interviews`}
            key = "newstage17Interviews"
            event_id = "5-17"
            pmOnly
          >
            <NewStage17Interviews />
          </ProtectedRoute>

          <ProtectedRoute
            path={`/dashboard/newstage17attendance`}
            key="newstage17attendance"
            event_id="5-17"
            pmOnly
          >
            <EventFormDashboard
              doctitle="New Stage17 Attendance"
              datafields={{ ...NewStage17DataFields }}
              datafieldsname={{ ...NewStage17AttendenceDataFieldsName }}
              eventid="5-17"
              api="/api/event/newstage17/attendance"
            />
          </ProtectedRoute>

          {EventsList.map((e, key) => {
            return e.protected ? (
              <ProtectedRoute path={`${e.path}`} exact key={key}>
                <EventMain
                  doctitle={e.doctitle}
                  headerconfig={e.headerconfig}
                  datafields={e.datafields}
                  datafieldsname={e.datafieldsname}
                  datafieldsdep={e.datafieldsdep}
                  eventid={e.eventid}
                  api={e.apipostendpoint}
                  QRcode={e.QRcode}
                  cautions={e.cautions}
                  sendMail={e.sendMail}
                  updateForm={e.updateForm}
                  constraint={e.constraint}
                />
              </ProtectedRoute>
            ) : (
              <Route path={`${e.path}`} exact key={key}>
                <EventMain
                  closed={e.closed}
                  doctitle={e.doctitle}
                  headerconfig={e.headerconfig}
                  datafields={e.datafields}
                  datafieldsname={e.datafieldsname}
                  datafieldsdep={e.datafieldsdep}
                  eventid={e.eventid}
                  api={e.apipostendpoint}
                  QRcode={e.QRcode}
                  cautions={e.cautions}
                  sendMail={e.sendMail}
                  updateForm={e.updateForm}
                  constraint={e.constraint}
                  noRepeat={e.noRepeat}
                />
              </Route>
            );
          })}

          {EventsList.map((e, key) => {
            if (!e.updateForm)
              if (e.charity)
                return (
                  <ProtectedRoute path={`/dashboard${e.path}`} key={key} charityOnly>
                    <EventFormDashboard
                      doctitle={e.doctitle}
                      datafields={e.datafields}
                      datafieldsname={e.datafieldsname}
                      eventid={e.eventid}
                      api={e.apigetendpoint}
                      apipatchendpoint={e.apipatchendpoint}
                      apideleteendpoint={e.apideleteendpoint}
                    />
                  </ProtectedRoute>
                );
              else
                return (
                  <ProtectedRoute
                    path={`/dashboard${e.path}`}
                    key={key}
                    pmOnly
                    event_id={e.eventid}
                  >
                    <EventFormDashboard
                      doctitle={e.doctitle}
                      datafields={e.datafields}
                      datafieldsname={e.datafieldsname}
                      eventid={e.eventid}
                      api={e.apigetendpoint}
                      apipatchendpoint={e.apipatchendpoint}
                      apideleteendpoint={e.apideleteendpoint}
                      QRcode={e.QRcode}
                    />
                  </ProtectedRoute>
                );
          })}

          {EventsList.map((e, key) => {
            const feedbackheader = {
              ...e.headerconfig,
              maintext: "Feedback",
              slogan: e.headerconfig.maintext + " feedback!",
            };
            return (
              e.feedback && (
                <Route path={`${e.path}/feedback`} key={key}>
                  <FeedbackMain
                    headerconfig={feedbackheader}
                    doctitle={e.feedbackconfig.doctitle}
                    datafields={e.feedbackconfig.datafields}
                    datafieldsname={e.feedbackconfig.datafieldsname}
                    cautions={e.feedbackconfig.feedbackcautions}
                    api={e.feedbackconfig.feedbackapipostendpoint}
                  />
                </Route>
              )
            );
          })}

          {EventsList.map((e, key) => {
            if (e.attendance)
              return (
                <ProtectedRoute
                  path={`/dashboard/attendance${e.path}`}
                  key={key}
                  pmOnly
                  event_id={e.eventid}
                >
                  <EventFormDashboard
                    doctitle={e.doctitle + ` attendance`}
                    datafields={e.datafields}
                    datafieldsname={e.attendancedatafieldsname}
                    eventid={e.eventid}
                    api={e.apiattendancegetendpoint}
                    attendance={e.attendance}
                  />
                </ProtectedRoute>
              );
          })}

          {EventsList.map((e, key) => {
            if (e.feedback)
              return (
                <ProtectedRoute path={`/dashboard/feedback${e.path}`} key={key}>
                  <EventFormDashboard
                    doctitle={e.feedbackconfig.doctitle}
                    datafields={e.feedbackconfig.datafields}
                    datafieldsname={e.feedbackconfig.datafieldsname}
                    eventid={e.eventid}
                    api={e.feedbackconfig.feedbackapigetendpoint}
                  />
                </ProtectedRoute>
              );
          })}

          <Redirect to="/404" />
        </Switch>
      </AnimatePresence>
    </div>
  );
}
