import React, { useContext, useState } from "react";
import Logo from "../../img/logo.png";
import { Link, NavLink } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { FaBars } from "react-icons/fa";
import events from "../../pages/events/EventsList";

export default function Sidebar() {
  const { logout, role, event_id } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const showSidebar = () => {
    setShow(!show);
  };
  return (
    <nav className={show ? "navigation-sidebar mobile-sidebar" : "navigation-sidebar"}>
      <button className="btn mobile-menu" onClick={showSidebar}>
        <FaBars />
      </button>
      <div className="content">
        <div className="logo">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        <ul>
          <li>
            <NavLink to="/dashboard" exact>
              Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard/ChangePassword">Change Password</NavLink>
          </li>
          {role === "admin" && (
            <>
              <li>
                <NavLink to="/dashboard/database">Database</NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/users">Users</NavLink>
              </li>
            </>
          )}
          {
            (role === "admin" || role === "pm" || role === "technical" || role === "charity" ) && (
              <li>
                <NavLink to="/dashboard/events">Events</NavLink>
              </li>
            )
          }
          {
            (role === "admin" || role === "smm") && (
              <li>
                <NavLink to="/dashboard/blogs">Blogs</NavLink>
                <NavLink to="/dashboard/categories">Categories</NavLink>
              </li>
            )
          }
        </ul>
        <button className="btn logout-btn" onClick={logout}>
          Logout
        </button>
      </div>
    </nav>
  );
}
