import React, { useState, useEffect, useRef } from "react";
import EventLayout from "../../components/EventLayout";
import swal from "sweetalert";
import axios from "axios";
import { useQrCode } from "../../QrCode";
import newstage17Cover from "../../img/stage 17.png"


export default function NewStage17() {

    const [formData, setFormData] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [attended,setAttended] = useState(false);
    const [qrCode,setQrCode] = useQrCode(null);
    const [showqr,setShowQr] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        document.title = `180Daraga | New Stage 17`;
    });
    
    
    
    const headerprops = {
        maintext: "NewStage17",
        backgroundimg: newstage17Cover,
        slogan: "NewStage17 is under the way"
    }

    const datafields = {
        name: "text",
        email: "text",
        age : "text",
        phone: "text",
        city : ["Cairo", "Giza", "Alex","Other"],
        district : ["Maadi", "Haram", "Dokki", "October" , "5th Settlement", "Nasr City", "Other"],
        university: ["Cairo", "Ain Shams", "Helwan", "Other"],
        collage: "text",
        university_level: ["1", "2", "3", "4","5"],
        attended_before: { radio: ["Yes", "No"] },
        year_attended : "text",
        how_did_you_hear: ["Facebook", "Instagram", "Linkedin","Friends", "Other"]
    };
    
    
    const datafieldsname = {
        name: "Name",
        email: "Email",
        age : "Age",
        phone: "Phone",
        city: "City",
        district: "District",
        university: "University",
        collage: "College",
        university_level: "Level",
        attended_before: "Attended New Stage Before?",
        year_attended : "In which year?" ,
        how_did_you_hear: "How did you hear about us?"
    };




    //regex validation  for email 
    const isValidEmail = (email) => {
        const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return emailRegex.test(email);
    }

    //regex validation for phone number
    const isValidPhone = (phone)=>{
        const phoneRegex = /^01[0-9]{9}$/;
        return phoneRegex.test(phone);
    }

    //validation for form that all inputs aren't empty
    const isFormDataValid = ()=>{
        if (Object.keys(formData).length === 0)
            return false;
        for (const key in formData) {
            if (!formData[key])
            return false;
        }
        return true;
    }


    //handle click for radio buttons
    const handleclick = (e)=>{
        e.target.value === "Yes" ? setAttended(true) : setAttended(false);
        console.log(attended);
        
    }


    //handle change for inputs
    const handlechange = (e)=>{
        let {name , value} = e.target;
        if (name === "email") {
            if (value.indexOf("‏") !== -1) {
                value = value.slice(0, value.lastIndexOf("‏"));
            }
        }
        
        setFormData({...formData , [name]: value.trim()});
        // console.log(formData);
    }


    //submit form
    const handlesubmit = (e)=>{
        e.preventDefault();
        setDisabled(true);
        console.log(formData);
        
        const formKeys = Object.keys(formData);
        const validFormDataLength = attended ? Object.keys(datafields).length : Object.keys(datafields).length - 1;
        // {console.log(formKeys.length);}
        
        if (!isFormDataValid() || formKeys.length < validFormDataLength) {
            swal({
                title : "OOPS!",
                text : "You have to fill out all required fields\n",
                icon:"error",
                button:"OK"
            })
            setDisabled(false);
            return;
        }

        if (formData.email && !isValidEmail(formData.email)) {
            swal({
                title: "Error!",
                text: "Please enter a valid email address\n",
                icon: "error",
                button:"OK"
            });
            setDisabled(false);
            return;
        }

        if (formData.phone && !isValidPhone(formData.phone)) {
            swal({
                title: "Error!",
                text: "Please enter a valid phone number\nphone number has to be 01XXXXXXXXX",
                icon: "error",
                button:"OK"
            });
            setDisabled(false);
            return;
        }

        
        axios
        .post('/api/event/newstage17', formData)
        .then(response => {
            console.log(response);
            
            if (response.status) {
                // swal({
                //     title: "Success!",
                //     text: "You have registered successfully",
                //     icon: "success",
                //     button:"OK"
                // })
                setShowQr(true);
                setQrCode(response.data.message);
                qrCode.append(ref.current);
            }else{
                swal({
                    title: "OOPS!",
                    text: "Something went wrong, please try again later",
                    icon: "error",
                    button:"OK"
                })
                setDisabled(false);
            }
        })
        .catch( error => {
            
                swal({
                    title: "Error!",
                    text: error.response.data.message,
                    icon: "error",
                    button:"OK"
                })
                setDisabled(false);
            
        })


    }


    return (
        <>
        {!showqr &&
        <EventLayout headerconfig={headerprops} closed= {false}>
            <div className="cautions">
            <h3>Cautions 📣</h3>
            <p style={{ marginTop: "20px" }}>
                Be sure to check your E-mail after submitting the form!
            </p>
            </div>

            <form  onSubmit={handlesubmit}>
                {Object.entries(datafields).map(([key,value]) => {

                            if (Object.keys(datafields[key])[0] === "radio") {
                                return(
                                    <div className="input-group" key={value}>
                                            <label htmlFor={key}>{datafieldsname[key]}
                                            <span className="required-marker">*</span>
                                            </label>
                                            <div className="radio-buttons-container">
                                                {datafields[key].radio.map((val,index) => (
                                                    <div key={index} className="radio-button">
                                                        <input 
                                                        type="radio" 
                                                        value={val}
                                                        name={key}
                                                        id = {index}
                                                        onChange={handlechange}
                                                        onClick={handleclick}
                                                        />
                                                        <label htmlFor={index}>{val}</label>
                                                    </div>
                                                    
                                                    ))
                                                } 
                                            </div>
                                    </div>
                                )
                            }
                            else if (typeof datafields[key] === "object" && datafields[key] instanceof Array) {
                                return(

                                    <div className="input-group" key={value}>
                                            <label htmlFor={key}>{datafieldsname[key]}
                                            <span className="required-marker">*</span>
                                            </label>
                                            <select name={key} id={key} onChange={handlechange}>
                                                <option value={""}>Select an option:</option>
                                                {datafields[key].map((val,index) =>(
                                                    <option value={val} key={index}>{val}</option>
                                            ))}
                                            </select>
                                    </div>
                                )
                            }else{
                                return(
                                    <div className="input-group" key={key}  hidden = {key === "year_attended" && !attended}>
                                            <label htmlFor={key}>{datafieldsname[key]}
                                                <span className="required-marker">*</span>
                                            </label>
                                            <input
                                            type={datafields[key]}
                                            name={key}
                                            id={key}
                                            autoComplete="on"
                                            onChange={handlechange}
                                            />
                                    </div>
                                )
                            }
                    })
                }
            <button className="btn btn-normal" disabled={disabled}>
                Submit
            </button>
            </form>
        </EventLayout>
        }
        {showqr && 
        <EventLayout headerconfig = {headerprops}>
            <div className="QRcodeContainer" style={{ textAlign: "center", margin: "30px" }}>
                <div className="QRcodeContainerText">
                    <p style={{ fontSize: "30px", margin: "20px" }}>Thank You!</p>
                    <p style={{ fontSize: "20px", margin: "20px" }}>
                        Please keep this QRcode somewhere safe, as you will need it to attend the event 👨‍💻
                    </p>
                </div>
                <div ref={ref}>
                    
                </div>
            </div>
        </EventLayout>
        }
        </>
    );
}