import React, { useState, useEffect, useRef } from "react";
import swal from "sweetalert";
import axios from "axios";
import EventLayout from "../../components/EventLayout";
import { useQrCode } from "../../QrCode";
import Recruitment24Cover from "../../img/events/Recruitment23 Cover.png"  ///waiting
export default function Recruitment24() {

    const sendMail = {  //waiting
        mailTitle: "Recruitment24",
        mailHeadParagraph: "Welcome aboard! <br><br>",
        mailBody: `
        Your individual registration has been confirmed, and we are exciting to start this exploration journey with you , <br>
        All you have to do is to keep the attached QR code in safe place so will use it in the upcoming stages in our journey. <br>
        For any assistance, reach out to us at [page messages or by replying to this email ]. Happy exploring! <br>
        `,
    }

    const [formData, setFormData] = useState({ sendMail, noRepeat: true });
    const [disabled, setDisabled] = useState(false);
    const [qrCode, setQrCode] = useQrCode(null);
    const [showQR, setShowQR] = useState(false);
    const ref = useRef(null);

    const headerprops = {
        maintext: "Recruitment24",
        slogan: "Recruitment24 IS UNDER THE WAY!!",  //waiting
        backgroundimg: Recruitment24Cover,  //waiting
    };

    const Committees = ["PR/FR", "Graphics", "Social Media Marketing", "Technical", "Video"];
    const handleChange = (e) => {
        if (e.target.name === "email") {
            if (e.target.value.indexOf("‏") !== -1) {
                e.target.value = e.target.value.slice(0, e.target.value.lastIndexOf("‏"));
            }
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
        console.log(formData);
    };

    useEffect(() => {
        document.title = `180Daraga | Recruitment24`;
    })

    const isValidEmail = (email) => {
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return emailRegex.test(email);
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        setDisabled(true);
        const phoneNo = /^\d{11}$/;
        if (!formData.name || !formData.phone || !formData.email || !formData.university || !formData.university_level || !formData.college
            || (formData.university === "Other" && !formData.university_name) || !formData.first_committee || !formData.second_committee || formData.second_committee === formData.first_committee) {
            swal({ title: "Error 😔", text: "Invalid input!", icon: "error" });
            setDisabled(false);
            return;
        }

        if (!isValidEmail(formData.email)) {
            swal({
                title: "Error 😔",
                text: "Please enter a valid email",
                icon: "error",
            });
            setDisabled(false);
            return;
        }

        if (!formData.phone.match(phoneNo)) {
            swal({
                title: "Error 😔",
                text: "Please enter a valid phone number\nIn likes of 01XXXXXXXXX",
                icon: "error",
            });
            setDisabled(false);
            return;
        }

        axios
            .post(`api/events/recruitment24`, formData)   
            .then((response) => {
                setShowQR(true);
                setQrCode(response.data.message);
                qrCode.append(ref.current);
            })
            .catch((err) => {
                swal({ title: "Error 😔", text: "Something wrong happend!", icon: "error" });
                setDisabled(false);
            });
    };

    return (
        <>
            {!showQR && (
                <EventLayout headerconfig={headerprops}>
                    <div className="cautions">
                        <h3>Cautions</h3>  
                        <p style={{ marginTop: "20px" }}>   
                            Be sure to check your E-mail after submitting the form!
                        </p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="input-group" key="name">
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                name="name"
                                id="name"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-group" key="email">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-group" key="phone">
                            <label htmlFor="phone">Phone</label>
                            <input
                                type="text"
                                name="phone"
                                id="phone"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="university">University</label>
                            <select name="university" required onChange={handleChange}>
                                <option value="" defaultValue>
                                    Select an option:
                                </option>
                                <option value="Cairo">Cairo</option>
                                <option value="Ain Shams">Ain Shams</option>
                                <option value="Helwan">Helwan</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        {
                            formData.university === "Other" && (
                                <div className="input-group">
                                    <label htmlFor="university_name">University Name</label>
                                    <input type="text" name="university_name" onChange={handleChange} required />
                                </div>
                            )
                        }
                        <div className="input-group" key="college">
                            <label htmlFor="college">College</label>
                            <input
                                type="text"
                                name="college"
                                id="college"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="university_level">University Level</label>
                            <select name="university_level" required onChange={handleChange}>
                                <option value="" defaultValue>
                                    Choose an option:
                                </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                        </div>
                        <div className="input-group">
                            <label htmlFor="first_committee">First Committee</label>
                            <select name="first_committee" required onChange={handleChange}>
                                <option value="" defaultValue>
                                    Choose an option:
                                </option>
                                {
                                    Committees.map((el) => {
                                        if (el != formData.second_committee)
                                            return <option value={el}> {el}</option>
                                    })
                                }
                            </select>
                        </div>
                        {
                            formData.first_committee && (
                                <div className="input-group">
                                    <label htmlFor="second_committee">Second Committee</label>
                                    <select name="second_committee" required onChange={handleChange}>
                                        <option value="" defaultValue>
                                            Choose an option:
                                        </option>
                                        {
                                            Committees.map((el) => {
                                                if (el != formData.first_committee)
                                                    return <option value={el}> {el}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            )
                        }
                        <button className="btn btn-normal" disabled={disabled}>
                            submit
                        </button>
                    </form>
                </EventLayout>
            )}
            {showQR && (
                <EventLayout headerconfig={headerprops}>
                    <div className="QRcodeContainer" style={{ textAlign: "center", margin: "30px" }}>
                        <div className="QRcodeContainerText">
                            <p style={{ fontSize: "30px", margin: "20px" }}>Thank You!</p>
                            <p style={{ fontSize: "20px", margin: "20px" }}>
                                Please keep this QRcode somewhere safe, as you will need it to attend the event
                            </p>
                        </div>
                        <div ref={ref} />
                    </div>
                </EventLayout>
            )}
        </>
    )
}